<template>

  <div>
        <div>
            <template>
                <v-toolbar
                    flat
                >
                <v-toolbar-title>{{ $t('Competitions') }}</v-toolbar-title>
                <v-spacer></v-spacer>

                </v-toolbar>
            </template>
        </div>
        <div class="mt-4 mb-15">

            <div>
                <template>
                    <v-toolbar flat >
                        <v-text-field
                        v-model="options.search"
                        append-icon="mdi-magnify"
                        :label="$t('Search')"
                        single-line
                        hide-details
                        clearable
                        class="shrink mr-5"
                        dense
                        ></v-text-field>
                        <v-select
                        :items="[{name:$t('Start Date'),value:'startDate'},{name:$t('End Date'),value:'endDate'}]"
                        item-text="name"
                        item-value="value"
                        v-model="options.sortBy"
                        clearable
                        single-line
                        hide-details
                        :label="$t('Sort by')"
                        class="top-filters shrink"
                        dense
                        ></v-select>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
                            fab
                            dark
                            color="primary"
                            @click.stop="openAddCompetitionDialog(false)"
                        >
                            <v-icon
                            dark
                            >
                            mdi-plus
                            </v-icon>
                        </v-btn>

                    </v-toolbar>
                </template>
            </div>
            <div class="mt-4">
                <v-row>
                    <v-col
                    v-for="(competition,index) in competitions" v-bind:key="index"
                    cols="12"
                    md="3"
                    sm="4"
                    >
                        <v-card class="competition-card" :to="{name: 'CompetitionOverview',query: { id: competition.id}}">
                            <v-card-title>
                                <div class="comp-emoji">{{competition.emoji?competition.emoji:'🏆'}}</div>
                                <div v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1" class="card-action">
                                    <v-btn @click.prevent="openAddCompetitionDialog(true,competition)" x-small plain text icon class="mr-2">
                                        <v-hover v-slot="{ hover }">
                                            <v-icon :color="hover? 'green': 'grey'"
                                            >
                                            mdi-pencil
                                            </v-icon>
                                        </v-hover>
                                    </v-btn>

                                    <v-btn @click.prevent="openDeleteCompetitionDialog(competition.id,competition.name)" x-small plain text icon class="">
                                        <v-hover v-slot="{ hover }">
                                            <v-icon  :color="hover? 'primary': 'grey'"
                                            >
                                            mdi-delete
                                            </v-icon>
                                        </v-hover>
                                    </v-btn>
                                </div>
                            </v-card-title>
                            <v-card-text class="comp-name">
                                <p class="cmpt-text">{{competition.name}}</p>
                                <div class="day-left">{{getDaysleft(competition.endDate)}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog
        v-model="addCompetitionDialog"
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ addCompetitionTitle }}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                    ref="CompetitionForm"
                    v-model="validCompetition"
                    lazy-validation
                >
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                            v-model="addCompetition.name"
                            :label="$t('Name*')"
                            :rules="rules.name"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                            v-model="addCompetition.goal"
                            :label="$t('Goal')"
                            type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                        <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y

                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="addCompetition.emoji"
                                :label="$t('Select Emoji')"
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                        </template>
                        <VEmojiPicker @select="selectEmoji" />
                        </v-menu>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-select
                            v-model="addCompetition.users"
                            :items="users"
                            item-text="fullName"
                            item-value="id"
                            :label="$t('User')"
                            multiple
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            :label="$t('Select a Start Date')"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            :rules="rules.startDate"
                            required
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startDateFormatted"
                            no-title
                            @input="menu2 = false"
                            :first-day-of-week="1"
                        ></v-date-picker>
                        </v-menu>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            :label="$t('Select a End Date')"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDateFormatted"
                            no-title
                            @input="menu3 = false"
                            :first-day-of-week="1"
                        ></v-date-picker>
                        </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="salesPlanMeasureTypes"
                        item-text="name"
                        item-value="value"
                        v-model="addCompetition.type"
                        :label="$t('Type')"
                        :rules="rules.type"
                        required
                        >
                            <template v-slot:item="{item}">
                                {{$t(item.name)}}
                            </template>
                        </v-select>
                        </v-col>

                        <v-col
                        v-if="addCompetition.type==2"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="activityTypes"
                        item-text="name"
                        item-value="id"
                        v-model="addCompetition.activityTypeId"
                        :label="$t('Types of Activity')"
                        :rules="rules.activityTypeId"
                        required
                        ></v-select>
                        </v-col>

                        <v-col
                        v-if="addCompetition.type==0 || addCompetition.type==1"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="baseNumbers"
                        item-text="name"
                        item-value="value"
                        v-model="addCompetition.baseNumber"
                        :label="$t('Base Number')"
                        :rules="rules.baseNumber"
                        required
                        >
                            <template v-slot:item="{item}">
                                {{$t(item.name)}}
                            </template>
                        </v-select>
                        </v-col>

                        <v-col

                        v-if="addCompetition.baseNumber==3 && (addCompetition.type==0 || addCompetition.type==1)"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="customFields"
                        item-text="name"
                        item-value="id"
                        v-model="addCompetition.customFieldId"
                        :label="$t('Custom properties')"
                        :rules="rules.customFieldId"
                        required
                        clearable
                        ></v-select>
                        </v-col>



                        <v-col
                        v-if="addCompetition.type==0 || addCompetition.type==1"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="solutionTypes"
                        item-text="name"
                        item-value="id"
                        v-model="addCompetition.solutionTypeId"
                        :label="$t('Solution type')"
                        :rules="rules.solutionTypeId"
                        required
                        clearable
                        ></v-select>
                        </v-col>

                        <v-col
                        v-if="addCompetition.type==0 || addCompetition.type==1"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="clientTypes"
                        item-text="name"
                        item-value="id"
                        v-model="addCompetition.clientTypeId"
                        :label="$t('Client type')"
                        :rules="rules.clientTypeId"
                        required
                        clearable
                        ></v-select>
                        </v-col>

                        <v-col
                        v-if="addCompetition.type==0 || addCompetition.type==1"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="DealsProperties"
                        item-text="name"
                        item-value="value"
                        v-model="addCompetition.dealsProperty"
                        :label="$t('Deal properties')"
                        :rules="rules.dealsProperty"
                        required
                        clearable
                        >
                            <template v-slot:item="{item}">
                                {{$t(item.name)}}
                            </template>
                        </v-select>
                        </v-col>

                        <v-col
                        v-if="addCompetition.type==0 || addCompetition.type==1"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-select
                        :items="RelationsProperties"
                        item-text="name"
                        item-value="value"
                        v-model="addCompetition.relationsProperty"
                        :label="$t('Relation properties')"
                        :rules="rules.relationsProperty"
                        required
                        clearable
                        >
                            <template v-slot:item="{item}">
                                {{$t(item.name)}}
                            </template>
                        </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <small>{{$t('*indicates required field') }}</small>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green"
                text
                @click="addCompetitionDialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="primary"
                @click="saveCompetition"
                :disabled="!validCompetition"
            >
               {{$t('Save')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="deleteCompetitionDialog"
        max-width="400px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ $t('Remove Competition') }}</span>
            </v-card-title>
            <v-card-text>{{$t('Are you sure you want to remove the Competition with title ')+deleteCompetitionName+'?'}}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="deleteCompetitionDialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="DeleteCompetition"
            >
               {{$t('Yes')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import { VEmojiPicker } from 'v-emoji-picker';

    export default {
        components: {
            VEmojiPicker
        },
        data(){
            return{
                validCompetition:true,
                rules: {
                    name:[
                        value => !!value || this.$t('Required.'),
                        value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                    ],
                    startDate: [
                      value => !!value || this.$t('Required.'),
                    ],
                    type:[
                        value => !!value || value == 0 || this.$t('Type is Required'),
                    ],
                },

                addCompetition:{},
                addCompetitionDialog: false,
                addCompetitionTitle:'',
                deleteCompetitionDialog:false,
                deleteCompetitionID:"",
                deleteCompetitionName:"",

                users:[],
                competitions:[],
                salesPlanMeasureTypes:[],
                activityTypes:[],
                baseNumbers:[],
                customFields:[],
                solutionTypes:[],
                clientTypes:[],
                DealsProperties:[],
                RelationsProperties:[],
                options:{
                    page:1,
                    itemsPerPage:10,
                    sortBy:'endDate',
                    sortDesc:true
                },

                menu2:false,
                menu3:false,
                menu4:false,
                startDateFormatted : "",
                startDate: "",
                endDateFormatted : "",
                endDate: "",
            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchCompetitions()
                },
                deep: true,
            },

            startDateFormatted () {
                this.startDate = this.formatDate(this.startDateFormatted)
            },
            endDateFormatted () {
            this.endDate = this.formatDate(this.endDateFormatted)
            },
            startDate () {
                this.startDateFormatted = this.reFormatDate(this.startDate)
            },
            endDate () {
                this.endDateFormatted = this.reFormatDate(this.endDate)
            },

        },
        mounted ()
        {
            this.fetchCompetitions();
        },
        created: function()
        {
            this.fetchUsers();
            this.fetchSalesPlanMeasureTypes()
            this.fetchActivityTypes()
            this.fetchBaseNumbers()
            this.fetchCustomFields()
            this.fetchSolutionTypes()
            this.fetchClientTypes()
            this.fetchDealsProperties()
            this.fetchRelationsProperties()
        },

        methods: {
            selectEmoji(emoji) {
                this.addCompetition.emoji = emoji.data;
                this.menu4 = false;
            },
            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day.substring(0, 2)}-${month}-${year}`
            },
            reFormatDate (date) {
                if (!date) return null

                const [day, month, year] = date.split('-')
                return `${year}-${month}-${day}`
            },
            getDaysleft(endDate){
                var dayleft = 0;
                var start = new Date();
                var end = new Date(endDate);
                dayleft = (end - start) / 1000 / 60 / 60 / 24;
                dayleft = dayleft - (end.getTimezoneOffset() - start.getTimezoneOffset()) / (60 * 24);
                dayleft = Math.round(dayleft);

                if(endDate == null){
                  return this.$t("Competition without end date")
                }
                else if(dayleft < 1){
                    return this.$t("Competition Completed")
                }else if(dayleft==1){
                    return dayleft + " " + this.$t('day left')
                }else{
                    return dayleft + " " + this.$t('days left')
                }
            },
            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:"";
            },
            fetchUsers()
            {
              let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=false';
              this.axios.get(uri).then((response) => {
                  let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=false';
                  this.axios.get(uri2).then((response) => {
                      this.users = response.data.filter(function(user){
                          return user.role == 1||user.role == 2;
                      });
                  });
              });
            },
            fetchActivityTypes(update=null)
            {
                if(this.$store.state.user.user.role < 2){
                    let uri = this.$urlPrefix + '/ActivityType/CountAll';
                    this.axios.get(uri).then((response) => {
                        let uri2 = this.$urlPrefix + '/ActivityType/GetList?start=0&count='+response.data;
                        this.axios.get(uri2).then((response) => {
                            this.activityTypes = response.data;
                            this.activityTypes.forEach(activityType =>{
                            if(this.activities[activityType.id]){
                                this.activities[activityType.id] = {activityTypeId:activityType.id,amount:0}
                            }
                            })
                            if(update){
                                this.getItem();
                            }
                            this.loading = false;
                        });
                    });
                }
            },

            fetchSalesPlanMeasureTypes()
            {
                let uri2 = this.$urlPrefix + '/SalesPlanMeasureType/GetAll';
                this.axios.get(uri2).then((response) => {
                    this.salesPlanMeasureTypes = response.data;
                    this.loading = false;
                });
            },

            fetchBaseNumbers()
            {
                let uri2 = this.$urlPrefix + '/SalesPlanMeasureBaseNumber/GetAll';
                this.axios.get(uri2).then((response) => {
                    this.baseNumbers = response.data;
                    this.loading = false;
                });
            },

            fetchCustomFields()
            {
                let uri2 = this.$urlPrefix + '/SalesPlanMeasureBaseNumber/GetCustomFields';
                this.axios.get(uri2).then((response) => {
                    this.customFields = response.data;
                    this.loading = false;
                });
            },

            fetchSolutionTypes()
            {
                if(this.$store.state.user.user.role < 2){
                    let uri = this.$urlPrefix + '/SolutionType/CountAll';
                    this.axios.get(uri).then((response) => {
                        let uri2 = this.$urlPrefix + '/SolutionType/GetList?start=0&count='+response.data;
                        this.axios.get(uri2).then((response) => {
                            this.solutionTypes = response.data;
                            this.loading = false;
                        });
                    });
                }
            },

            fetchClientTypes()
            {
                if(this.$store.state.user.user.role < 2){
                    let uri = this.$urlPrefix + '/ClientType/CountAll';
                    this.axios.get(uri).then((response) => {
                        let uri2 = this.$urlPrefix + '/ClientType/GetList?start=0&count='+response.data;
                        this.axios.get(uri2).then((response) => {
                            this.clientTypes = response.data;
                            this.loading = false;
                        });
                    });
                }
            },

            fetchDealsProperties()
            {
                let uri2 = this.$urlPrefix + '/SalesPlanMeasureDealsProperty/GetAll';
                this.axios.get(uri2).then((response) => {
                    this.DealsProperties = response.data;
                    this.loading = false;
                });
            },

            fetchRelationsProperties()
            {
                let uri2 = this.$urlPrefix + '/SalesPlanMeasureRelationsProperty/GetAll';
                this.axios.get(uri2).then((response) => {
                    this.RelationsProperties = response.data;
                    this.loading = false;
                });
            },
            fetchCompetitions(){

                let uri = this.$urlPrefix + '/Competition/GetList?start='+ (this.options.page -1) * this.options.itemsPerPage+'&count='+this.options.itemsPerPage;

                if(this.options.search != undefined && this.options.search != ""){
                    uri += '&search='+this.options.search;
                }
                if(this.options.sortBy){
                    uri += '&sort='+this.options.sortBy;
                }
                if(this.options.sortDesc){
                    uri += '&sortDescending='+this.options.sortDesc;
                }

                this.axios.get(uri).then((response) => {
                    this.competitions = response.data;
                });
            },

            openAddCompetitionDialog(isEdit,Competition){
                if(isEdit){
                    this.addCompetitionTitle = this.$t('Edit Competition')
                    this.addCompetition = Competition
                    if(Competition.startDate != null){
                      this.startDate = this.formatDate(new Date(Competition.startDate).toISOString().substr(0, 10));
                      this.startDateFormatted = new Date(Competition.startDate).toISOString().substr(0, 10);
                    }
                    if(Competition.endDate != null){
                      this.endDate = this.formatDate(new Date(Competition.endDate).toISOString().substr(0, 10));
                      this.endDateFormatted = new Date(Competition.endDate).toISOString().substr(0, 10);
                    }
                }else{
                    this.startDate = ''
                    this.endDate = ''
                    this.addCompetitionTitle = this.$t('Add Competition')

                    if(this.$refs.CompetitionForm!=undefined){
                        this.$refs.CompetitionForm.reset()
                    }
                    this.addCompetition = {};
                }
                this.addCompetitionDialog = true
            },
            saveCompetition(){
                if(this.$refs.CompetitionForm.validate()){

                    this.addCompetition.startDate = this.startDateFormatted;
                    this.addCompetition.endDate = this.endDateFormatted;


                    if (this.addCompetition.id != undefined && this.addCompetition.id != "") {
                        if(this.addCompetition.startDate == ""){
                          this.addCompetition.startDate = null
                        }
                        if(this.addCompetition.endDate == ""){
                          this.addCompetition.endDate = null
                        }

                        let uri = this.$urlPrefix + '/Competition/Update';
                        this.axios.put(uri, this.addCompetition).then((response) => {
                            if(response.status == 204 || response.status == 200){
                                    this.addCompetition = []
                                    this.fetchCompetitions()
                                    this.addCompetitionDialog = false
                            }
                        });
                    } else {
                        if(this.addCompetition.startDate == ""){
                          this.addCompetition.startDate = null
                        }
                        if(this.addCompetition.endDate == ""){
                          this.addCompetition.endDate = null
                        }

                        let uri = this.$urlPrefix + '/Competition/Add';
                        this.axios.post(uri, this.addCompetition).then((response) => {
                            if(response.status == 200){
                                this.addCompetition = []
                                this.fetchCompetitions()
                                this.addCompetitionDialog = false
                            }
                        });
                    }
                }
            },
            openDeleteCompetitionDialog(id,name){

                this.deleteCompetitionID = id
                this.deleteCompetitionName = name
                this.deleteCompetitionDialog = true
            },
            DeleteCompetition(){
                if(this.deleteCompetitionID !=""){
                    let uri = this.$urlPrefix + '/Competition/Delete?id='+this.deleteCompetitionID;
                    this.axios.delete(uri).then((response) => {
                        if(response.data == true){
                            this.fetchCompetitions()
                            this.deleteCompetitionDialog = false
                            this.deleteCompetitionID = ""
                        }else{
                            this.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:"Unable to delete competition"})
                        }
                    });
                }
            },

        }
    }
</script>
<style lang="scss">
    .card-action {
        display: none;
    }
    .competition-card:hover .card-action {
        display: block;
    }
    p.cmpt-text {
        color: #424242;
        font-size: 15px;
        font-weight: 500;
    }

    .v-card__text.comp-name {
        text-align: center;
    }

    .competition-card .v-card__title {
        text-align: center;
        display: block;
    }

    .competition-card .card-action {
        position: absolute;
        top: 0;
        right: 5px;
    }
    .day-left {
        margin-left: 10px;
        font-size: 12px;
    }

    @media only screen and (max-width: 600px) {
        .competition-card .card-action {
            display: block;
        }
    }
</style>
